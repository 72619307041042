.mob-footer-font {
    font-family: 'Raleway', 'Regular';
    color: #606062;
    font-size: 15px;
}
.mob-footer-heading {
    color: #606062;
    font-family: 'Raleway';
    font-weight: bold;
    font-size: 20px;
}