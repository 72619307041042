.hiring {

    font-size: 40px;
    padding-top: 20px;
    margin-left: 30px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.hiringparagraphssssss {
    font-size: 15px;
    text-align: left;
    padding-top: 20px;
    font-weight: 500;
    color: #707070;
    margin-left: 30px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



}



/* ad$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ */


@media (max-width: 480px) {
    .heading-nutshel {
        font-size: 20px;
        padding-top: 20px;
        margin-left: 30px;
        font-weight: 400;
        font-family: 'Lato', 'Regular';
    }
}

@media (min-width: 480px) {

    .heading-nutshel {
        font-size: 40px;
        padding-top: 20px;
        margin-left: 30px;
        font-weight: 400;
        font-family: 'Lato', 'Regular';
    }
}













.hiringparagraph {
    font-size: 20px;
    text-align: left;
    padding-top: 20px;
    font-weight: 500;
    color: #707070;
    margin-left: 30px;
    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    /* font-weight: 500; */
    font-family: 'Lato', sans-serif;
}

.mobilesteper {
    margin: auto;
    padding: auto;
    text-align: center;
    padding-left: 40%;
    padding-right: 50%;
}



@media screen and (max-width: 650px) {
    .mobilesteper {

        margin: auto;
        padding: auto;
        text-align: center;
        padding-left: 20%;


    }
}