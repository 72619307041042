body {
    margin: 0;
    padding: 0;
}

/* .App {
    font-family: "Poppins";
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 100%;
} */


.customer-3 {

    /* margin: 10px; */
    color: #606062;
    font-size: 25px;
    font-weight: 500;
    font-family: 'Raleway', 'Regular';




}


.numbers {
    display: flex;
    justify-content: space-around;
}

.number {
    margin-right: 10px;
    margin-left: 10px;
}

.number .counter {
    font-weight: bold;
    font-style: italic;
    font-size: 1.9em;
    /* margin: 6px 0; */
}

.number span {
    font-size: 1.2em;
    /* display: block; */
}