.StartFont {
    /* color: black; */
    color: #707070;
    /* opacity: 100%; */



}

/* .Containerstart {
    background-color: #D1F0FF;
    opacity: 100%;




} */

.already {
    color: #606062;
    opacity: 100%;
    font-family: 'Lato', ;
    font-size: 20px;

    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */

    font-weight: 500;

}


.vl {
    text-align: center;
    padding-left: 4px;
    border-left: 1px solid #606062;
    height: 140px;
    /* color: #606062; */
}

.spancustomer {
    color: #606062;
    font-size: 25px;
    font-weight: 500;
    font-family: 'Raleway', 'Regular';


}

.custumerdisplay {

    /* display: inline-flex; */
    display: flex;
    /* display: inline; */
    /* align-content: center; */
    align-items: flex-end;

}



.flex-container {
    display: flex;
    background-color: #f1f1f1;
    text-align: center;
}

.customericon {

    float: left;
    /* width: 150px;
    height: 150px; */

    padding: 10px;
    position: relative;
    /* width: 120px;
    height: 120px; */
    width: 115px;
    height: 100px;

    /* object-fit: cover; */

}






@media screen and (max-width: 950px) {
    .vl {
        text-align: center;
        padding-left: 4px;
        border-left: 1px solid #606062;
        height: 0px;
        /* color: #606062; */
    }

}