.hoverfont:hover {
    color: #1C6BFE;
    /* background-color: brown; */
    /* font-size: 40px; */
    font-weight: bolder;

}

.hoverfontdropdown:hover {

    color: #1C6BFE;
}

.bookbuttonlink {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


    font-size: 14px;
    font-weight: 400;
    text-align: center;
    padding: 10px;
    width: 150px;



}





@media screen and (max-width: 1150px) {

    .responsivenavbar {
        /* background-color: red; */
        /* padding-right: 2px; */
    }



}



/* ############################## app bar media quary */



.linkappbar {
    text-align: center;
    font-size: 15px;
    font-weight: normal;
    color: #707070;
    text-decoration: none;
    padding-right: 50px;
    font-family: 'Rubik', 'Regular';
}


@media screen and (max-width: 1050px) {
    .linkappbar {
        text-align: center;
        font-size: 15px;
        font-weight: normal;
        color: #707070;
        text-decoration: none;
        padding-right: 20px;
    }
}


@media screen and (max-width: 800px) {
    .linkappbar {
        text-align: center;
        font-size: 10px;
        font-weight: normal;
        color: #707070;
        text-decoration: none;
        padding-right: 18px;
    }
}


@media screen and (max-width: 840px) {
    .linkappbar {
        text-align: center;
        font-size: 15px;
        font-weight: normal;
        color: #707070;
        text-decoration: none;
        padding-right: 10px;
    }
}


@media screen and (max-width: 840px) {


    .bookbuttonlink {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;


        font-size: 10px;
        font-weight: 400;
        text-align: center;
        padding: 10px;
        width: 100px;



    }

}