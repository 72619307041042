	/* .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {


		font-size: 15px;



	}
 */


	.formheading {
		color: #333333;
		opacity: 100%;
		text-align: left;
		font-size: 20px;

		padding-top: 10px;
		font-weight: 600;

		font-family: 'Lato', 'Bold';






	}


	.formbutton:hover {
		background-color: #065bfa;
	}

	.formbutton {
		text-align: center;

		margin-bottom: 20px;
		margin-top: 10px;
		background-color: #1C6BFE;
		opacity: 100%;
		color: #FFFFFF;
		width: 100%;
		border-radius: 5px;
		height: 35px;
		font-size: 15px;
		font-weight: 600;
		font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



	}

	.fieldset {
		border-radius: 5px;
		border-width: 1px;
		padding-left: 20px;
		width: 100%;
		text-align: left;
	}

	.legend_web {
		color: #333333;
		padding: 2px;
		font-size: 10px;
		opacity: 100%;

	}

	.inputfeild_web {
		border-width: 0px;
		padding-bottom: 8px;
		border-color: aliceblue;
		outline: none;
		border: 0px solid;
		font-size: 12px;
		font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
		text-align: left;
		width: 100%;
	}

	.errorfeild {
		text-align: left;
		display: block;
		color: red;
		padding-left: 20px;
		font-size: 10px;
		font-weight: 400;



	}