.profile-name {
    font-size: 18px;
    color: #fff;
    font-weight: 700;
    display: block;
}

.profile-email {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    margin-top: -15px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



}

.th_mouse_hover :hover {
    background-color: #0556ed;




}


.profile-table {

    text-align: center;
    cursor: pointer;

}

.profile-table {
    border: 1px solid #ffffff;

    text-align: center;
    padding: 8px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    border-style: groove;


}

.profile-bottom {

    border: 0px;
    color: #007bff;
    font-size: 15px;
    font-family: sans-serif;
    font-weight: 400;
    background-color: "none";




}


.user_button {

    background-color: black;

}