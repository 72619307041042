@media only screen and (min-width: 954px) {
    .sidebar_profile {
        display: none;

    }
}


/* Customize the scrollbar */
::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    /* Set the width of the scrollbar */
}

/* Customize the scrollbar thumb */
::-webkit-scrollbar-thumb {
    background-color: #bdc3c7;
    cursor: pointer;
    /* Change the background color of the thumb */
    border-radius: 0px;
    /* Round the corners of the thumb */
}

/* Customize the scrollbar track */
::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Change the background color of the track */
}


/* Apply custom styles to the Autocomplete component */
.MuiAutocomplete-root {
    /* Your custom styles for Autocomplete */
    /* For example, changing the border */
    border: none;

}

/* Apply custom styles to the input field within Autocomplete */
.MuiAutocomplete-root .MuiOutlinedInput-root {
    color: #64728c !important;
    width: 100% !important;
    border: 1px solid #e8eaef !important;
    /* height: 30px; */
    padding: 5px !important;
    font-size: 12px !important;
    margin-top: 4px !important;
    font-family: sans-serif !important;
    border-radius: 4px !important;
    max-height: 300px !important;
    min-height: 35px !important;

}

.MuiAutocomplete-root .MuiAutocomplete-tag {
    margin: 0px !important;
    max-width: calc(100% - 6px) !important;
    margin-top: -20px !important;
}



.MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 1px 1px 1px 1px !important;
}


.MuiChip-label {
    font-size: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* padding-left: 12px; */
    /* padding-right: 12px; */
    white-space: nowrap;
    padding-top: 10px;
    font-weight: 300;
    font-family: sans-serif;
    color: #64728C;
}




input::placeholder {
    color: #999;
    opacity: 0.5;
    /* Replace #999 with your desired color code */
}

/* DataTable.css */
.hide-icon {
    visibility: hidden;
}

/* Show the icon on row hover */
tr:hover .show-icon {
    visibility: visible;
    /* background-color: red; */
}