/* .parent {
    position: absolute;
    top: 0;
    left: 0;

}

.image1 {
    position: static;
    top: 0;
    left: 0;
    width: 50%;
    border: 1px solid #000000;
    
}

.image2 {
    position: static;
    top: -30px;
    left: 30px;
    width: 50%;
    border: 1px solid #000000;

    margin-top: -10px;
    margin-left: 20px;
   
} */
.fix_experince {
    padding-left: 100px;
}

.fixed_booklive {
    margin-top: 10%;
    margin-right: 100px;
}

@media only screen and (max-width: 576px) {

    .fixed_booklive {
        margin-right: 70px;
    }

    .fix_experince {
        padding-left: 65px;
    }
}



@media (max-width: 480px) {

    .exerience-font {
        font-size: 27px;
        margin-top: -12px;
        color: #101010;
        font-family: 'Lato', 'Black';
        font-weight: bold;
    }

}

@media (min-width: 480px) {

    .exerience-font {
        font-size: 40px;
        margin-top: -25px;
        color: #101010;
        font-family: 'Lato', 'Black';
        font-weight: bold;

    }
}

@media (min-width:600px) and (max-width:1200px) {
    .exerience-font {
        font-size: 27px;
        margin-top: -13px;
        color: #101010;
        font-family: 'Lato', 'Black';
        font-weight: bold;

    }
}



/* 111111111111111 */



@media (max-width: 480px) {

    .container-experience {
        padding-right: 0%;
        padding-left: 0%;
        /* margin-right: -10%; */
    }

}

@media (min-width: 480px) {

    .container-experience {
        padding-right: 8%;
        padding-left: 8%;
    }
}

.mob-already {

    color: #606062;
    font-family: Lato;
    font-size: 18px;
    opacity: 100%;
    font-weight: 500;
}

/* .mob-start {
    color: #101010;
    font-family: 'Lato', 'Black';
    font-weight: bold;
    font-size: 40px;
} */
@media screen and (max-width: 425px) {
    .mob-resp {
        padding-top: 50px;

    }
}


@media screen and (max-width: 425px) {

    .mobile-container {
        width: 420px;
    }
}