.scrolling_button {
    margin: auto;
    text-align: center;
    padding-left: 40%;
    padding-right: 40%;
    align-items: center;
}


@media only screen and (max-width: 576px) {
    .scrolling_button {
        margin: auto;
        text-align: center;
        padding-left: 25%;
        padding-right: 40%;
        align-items: center;
    }
}