.Startupimage {
    height: auto;
    width: 100%;
    object-fit: contain;
}

@media screen and (max-width: 650px) {
    .startupimage {
        height: 190px;
        width: 100%;
        object-fit: fill;
    }
}

.containers {
    position: relative;
    text-align: center;
    color: white;
}

.centered {
    position: absolute;
    top: 20%;
    left: 60%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 700;
    color: #1C6BFE;
    opacity: 100%;
    text-transform: uppercase;
    font-family: 'Lato', 'Black';
}

.youfont {

    position: absolute;
    top: 33%;
    font-size: 40px;
    font-weight: 600;
    color: #1C6BFE;
    opacity: 100%;
    text-transform: lowercase;
    font-family: 'Lato', 'Black';
}

.Growthfont {

    position: absolute;
    top: 40%;
    left: 70%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 700;
    color: #1C6BFE;
    opacity: 100%;
    text-transform: uppercase;
    font-family: 'Lato', 'Black';
}

.lowcasfont {

    position: absolute;
    top: 60%;
    left: 65%;
    transform: translate(-50%, -50%);
    font-size: 30px;
    font-weight: 400;
    color: #707070;
    opacity: 100%;
    text-transform: capitalize;
    font-family: 'Lato', 'Regular';
}

.GrowthfontButton {
    position: absolute;
    top: 77%;
    left: 60%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 700;
    opacity: 100%;
    font-family: 'Lato', 'Black';
    text-transform: uppercase;
}





/*  @@@@@@@@@@@@@@@@@@@@@@@@@@@@!!!!!!!!!!!!!!!!!!!!!!!!!!! mobile device*/

@media only screen and (min-width: 100px) and (max-width: 600px) {
    .centered {
        position: absolute;
        top: 20%;
        left: 60%;
        transform: translate(-50%, -50%);
        font-size: 15px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .youfont {

        position: absolute;
        top: 30%;
        font-size: 10px;
        font-weight: 600;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: lowercase;
        font-family: 'Lato', 'Black';
    }

    .Growthfont {

        position: absolute;
        top: 32%;
        left: 70%;
        transform: translate(-50%, -50%);
        font-size: 15px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .lowcasfont {
        position: absolute;
        top: 45%;
        left: 60%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-weight: 400;
        color: #707070;
        opacity: 100%;
        text-transform: capitalize;
        font-family: 'Lato', 'Regular';
    }

    .GrowthfontButton {
        position: absolute;
        top: 77%;
        left: 60%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-weight: 700;
        opacity: 100%;
        font-family: 'Lato', 'Black';
        text-transform: uppercase;
    }

}


/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@  tab device  */
@media only screen and (min-width: 600px) and (max-width: 800px) {
    .centered {
        position: absolute;
        top: 20%;
        left: 60%;
        transform: translate(-50%, -50%);
        font-size: 25px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .youfont {
        position: absolute;
        top: 33%;
        font-size: 15px;
        font-weight: 600;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: lowercase;
        font-family: 'Lato', 'Black';
    }

    .Growthfont {
        position: absolute;
        top: 32%;
        left: 65%;
        transform: translate(-50%, -50%);
        font-size: 25px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .lowcasfont {
        position: absolute;
        top: 45%;
        left: 65%;
        transform: translate(-50%, -50%);
        font-size: 15px;
        font-weight: 400;
        color: #707070;
        opacity: 100%;
        text-transform: capitalize;
        font-family: 'Lato', 'Regular';
    }

    .GrowthfontButton {
        position: absolute;
        top: 70%;
        left: 60%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-weight: 700;
        opacity: 100%;
        font-family: 'Lato', 'Black';
        text-transform: uppercase;
    }
}



/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ laptop device  */
@media only screen and (min-width: 800px) and (max-width: 1100px) {
    .centered {
        position: absolute;
        top: 20%;
        left: 60%;
        transform: translate(-50%, -50%);
        font-size: 35px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .youfont {

        position: absolute;
        top: 30%;
        font-size: 25px;
        font-weight: 600;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: lowercase;
        font-family: 'Lato', 'Black';
    }

    .Growthfont {

        position: absolute;
        top: 32%;
        left: 70%;
        transform: translate(-50%, -50%);
        font-size: 35px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .lowcasfont {
        position: absolute;
        top: 45%;
        left: 65%;
        transform: translate(-50%, -50%);
        font-size: 20px;
        font-weight: 400;
        color: #707070;
        opacity: 100%;
        text-transform: capitalize;
        font-family: 'Lato', 'Regular';
    }

    .GrowthfontButton {
        position: absolute;
        top: 67%;
        left: 65%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-weight: 700;
        opacity: 100%;
        font-family: 'Lato', 'Black';
        text-transform: uppercase;
    }
}



/* @@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@ laptop device  */
@media only screen and (min-width: 1100px) and (max-width: 1237px) {
    .centered {
        position: absolute;
        top: 20%;
        left: 60%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .youfont {

        position: absolute;
        top: 30%;
        font-size: 30px;
        font-weight: 600;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: lowercase;
        font-family: 'Lato', 'Black';
    }

    .Growthfont {

        position: absolute;
        top: 32%;
        left: 70%;
        transform: translate(-50%, -50%);
        font-size: 40px;
        font-weight: 700;
        color: #1C6BFE;
        opacity: 100%;
        text-transform: uppercase;
        font-family: 'Lato', 'Black';
    }

    .lowcasfont {
        position: absolute;
        top: 45%;
        left: 65%;
        transform: translate(-50%, -50%);
        font-size: 25px;
        font-weight: 400;
        color: #707070;
        opacity: 100%;
        text-transform: capitalize;
        font-family: 'Lato', 'Regular';
    }

    .GrowthfontButton {
        position: absolute;
        top: 65%;
        left: 65%;
        transform: translate(-50%, -50%);
        font-size: 10px;
        font-weight: 700;
        opacity: 100%;
        font-family: 'Lato', 'Black';
        text-transform: uppercase;
    }
}