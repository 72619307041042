.top-header-responsive {
    color: #707070;
    font-size: 15px;
    font-family: 'Rubik', sans-serif;
}

@media (max-width: 480px) {
    .mobiletopstripbar {
        width: 100%;
        text-align: left;


        /* padding-right: 115px; */
    }

}

@media (min-width: 480px) {

    .mobiletopstripbar {
        width: 100%;
        text-align: right;
        padding-right: 115px;
    }
}

@media screen and (max-width: 650px) {
    .toptripbar {
        width: 100%;
        text-align: left;
        padding-right: 155px;


    }
}

.logoappbar {

    padding-left: 15px;
    width: auto;
    height: 40px;
}


@media screen and (max-width: 800px) {
    .logoappbar {
        padding-left: 5px;
        width: auto;
        height: 15px;


    }
}

@media screen and (max-width: 1098px) {
    .logoappbar {
        padding-left: 5px;
        width: auto;
        height: 15px;

    }
}

.heading-contect-font {
    opacity: 100%;
    color: #707070;
    font-family: 'Rubik', sans-serif;
}

@media only screen and (max-width: 425px) {
    .heading-contect-font {
        opacity: 100%;
        color: #707070;
        font-size: 10px;
        font-family: 'Rubik', sans-serif;
    }

}