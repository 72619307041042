.reverse-boxes .revarse-grid {
    display: block;
    width: 100%;
    padding: 15px;
    border: none;
    margin-bottom: 5px;
    box-sizing: border-box;
    font-size: 1rem;
    text-align: center;
    text-decoration: none;
    /* background: gold; */
    color: #000;
}

.reverse-boxes .revarse-grid:nth-of-type(even) {
    /* background: #e6c200; */
}

.reverse-boxes .revarse-grid:focus {
    outline: none;
    color: #fff;
    background: firebrick;
}

@media screen and (max-width: 600px) {
    .reverse-boxes {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .reverse-boxes .revarse-grid:nth-of-type(1) {
        order: 1;
    }

    .reverse-boxes .revarse-grid:nth-of-type(2) {
        order: 2;
    }

    .reverse-boxes .revarse-grid:nth-of-type(3) {
        order: 4;
    }

    .reverse-boxes .revarse-grid:nth-of-type(4) {
        order: 3;
    }

    .reverse-boxes .revarse-grid:nth-of-type(5) {
        order: 5;
    }

    .reverse-boxes .revarse-grid:nth-of-type(6) {
        order: 6;
    }

}




/* @@@@@@@@@@@@@@@@@@@@@@ */

.hiring {

    font-size: 40px;
    padding-top: 20px;
    margin-left: 30px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.hiringparagraph {
    font-size: 20px;
    text-align: left;
    padding-top: 20px;
    font-weight: 500;
    color: #707070;
    margin-left: 30px;
    font-family: 'Lato', sans-serif;




}



@media (max-width: 480px) {

    .hiringbold {
        font-size: 30px;
        padding-top: 25px;
        margin-left: 30px;
        font-weight: bolder;
        color: #333333;
        font-family: 'Lato', "Black";
    }
}

@media (min-width: 480px) {


    .hiringbold {

        font-size: 38px;
        padding-top: 20px;
        margin-left: 30px;
        font-weight: bolder;
        font-family: 'Lato', "Black";
        text-align: left;
        color: #333333;

    }
}

@media (min-width:600px) and (max-width:900px) {
    .hiringbold {

        font-size: 30px;
        padding-top: 20px;
        margin-left: 30px;
        font-weight: bolder;
        font-family: 'Lato', "Black";
        color: #333333;

    }
}





.symboll-img {
    /* display: none; */
    position: absolute;
    /* left: -40px; */
    left: -3%;
    /* padding-top: 200px; */
    /* padding-top: 200px; */
    /* padding-top: 10px; */
    /* padding-left: 20px; */
    /* background-color: rebeccapurple; */
    margin-top: -2%;
    z-index: -1;
    /* padding-l
    eft: -20px; */

}

.symboll-img-emp {
    display: none;
    position: absolute;
    z-index: -1;
    /* top: -10px; */
    /* left: 580px; */
    left: 45%;
    margin-top: -70px;

}


@media screen and (max-width: 768px) {
    .symboll-img-emp {
        position: absolute;
        z-index: -1;
        /* top: -10px; */
        /* left: 580px; */
        left: 40%;
        margin-top: 40px;

    }

}