.StartFont {
    /* color: black; */
    color: #707070;
    /* opacity: 100%; */



}



.Containerstart {
    /* background-color: #D1F0FF; */
    background-color: #E7F3FE;
    opacity: 100%;
    padding: 2%;
    border-radius: 10px;
    z-index: 1;
    height: 300px;
}

.already {
    color: #606062;
    opacity: 100%;
    font-size: 18px;
    font-family: 'Lato', sans-serif;


    /* font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */

    font-weight: 500;

}


.vl {
    text-align: center;
    padding-left: 4px;
    border-left: 1px solid #606062;
    height: 140px;
    /* color: #606062; */
}






@media screen and (max-width: 900px) {



    .Startforfreecontainer {
        /* background-color: #1c9ada; */
        background-color: #E7F3FE;
        opacity: 100%;
        border-radius: 10px;
        padding-left: 25px;
        padding-right: 25px;
    }



}


.symboll-img-free {

    position: absolute;
    z-index: 1;
    margin-top: -25px;
    margin-left: -25px;



}

.Free-pricingcardsymboll {
    position: absolute;
    margin-left: 25%;
    margin-top: -3%;
}


.absouluteposition {
    color: #101010;
    margin: auto;
    padding: auto;
    text-align: center;
    position: relative;
    width: 300px;
    height: 10px;
    font-size: 35px;




}

.Free-pricingcardsymbollsss {
    position: absolute;
    margin-left: 252px;
    margin-top: -47px;



}



.startforfre-font {
    padding-top: 30px;

    color: #101010;
    font-family: 'Lato', 'Black';
    font-weight: bold;
    font-size: 40px;

}







@media screen and (max-width: 768px) {

    .display-none {
        display: none;

    }
}


@media screen and (max-width: 1025px) {

    .symboll-img-free {

        position: absolute;
        z-index: 1;
        margin-top: -13px;
        margin-left: -50px;



    }
}





@media screen and (max-width: 425px) {

    .already-responsive,
    .already {
        text-align: left;
        font-family: 'Lato', sans-serif;
        font-size: 10px;




    }
}