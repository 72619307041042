.first-demo-top {
    font-size: 80px;
    color: #1C6BFE;
    font-family: "Lato";
    font-weight: bold;
}

.monssss {
    line-height: 60px;
}

.sub-paragraph-st {
    font-size: 24px;
    color: #707070;
    font-family: "Lato";



}

.small-font-size {

    font-size: 35px;
    color: #1C6BFE;

}

.break-startup {
    display: none;
}

.dialoag-pop-form {
    padding-right: 30%;
}

@media (min-width: 220px) and (max-width: 600px) {
    .dialoag-pop-form {
        padding-right: 0%;
    }

    .first-demo-top {
        font-size: 20px;
        color: #1C6BFE;

    }

    .monssss {
        line-height: 20px;
    }

    .small-font-size {

        font-size: 10px;
    }

    .sub-paragraph-st {


        font-size: 10px;
    }

    .break-startup {
        display: block;
    }


}


@media only screen and (min-width: 600px) and (max-width: 768px) {
    .dialoag-pop-form {
        padding-right: 0%;
    }

    .first-demo-top {
        font-size: 40px;
        color: #1C6BFE;

    }

    .monssss {
        line-height: 30px;

    }

    .small-font-size {

        font-size: 20px;
    }

    .sub-paragraph-st {


        font-size: 20px;
    }

    .break-startup {
        display: block;
    }


}