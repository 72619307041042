.copyrightleft {
    color: #606062;
    padding-left: 20px;
    margin-left: 40px;
    text-align: left;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Raleway', 'Regular';
}



.copyrightright {
    color: #606062;
    padding-right: 20px;
    margin-right: 40px;
    text-align: right;
    font-size: 17px;
    font-weight: 500;
    font-family: 'Raleway', 'Regular';
}

@media screen and (max-width: 600px) {
    .copyrightright {
        color: #606062;
        /* text-align: right; */
        font-size: 13px;
        font-weight: 500;
        font-family: 'Raleway', 'Regular';
    }
}

@media screen and (max-width: 900px) {
    .copyrightright {
        text-align: left;
        padding: 0%;
        margin: 0%;
        padding-left: 0px;
        margin-left: 0px;
    }
}







@media screen and (max-width: 900px) {
    .copyrightleft {
        /* background-color: lightgreen; */
        text-align: left;
        padding: 0%;
        margin: 0%;
        padding-left: 0px;
        margin-left: 0px;
    }
}
@media screen and (max-width: 600px) {
    .copyrightleft {
        /* background-color: lightgreen; */
        text-align: left;
        padding: 0%;
        margin: 0%;
        padding-left: 0px;
        margin-left: 0px;
        font-size: 13px;
    }
}