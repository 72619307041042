.formposition {

    margin: auto;
    /* margin-top: 10%; */
    padding-top: 10%;
    padding: auto;
    text-align: center;
    align-items: center;
    width: 20%;
    height: auto;








}

.login-bg {

    opacity: 100%;
    background-color: #EFF5FF;
    height: 700px;
    margin: auto;
    padding: auto;
    text-align: center;

}

.loginforminput {
    background-color: #FFFFFF;
    width: 300px;
    height: 35px;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    /* border: 1px solid #1C6BFE; */
    border-radius: 4px;
    box-sizing: border-box;
    color: #707070;
    font-size: 12px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.loginforminput {
    /* opacity: 100%; */

    border: 1px solid #a2bcf3;


}

.login-submit-button {

    margin-top: 5px;
    width: 300px;
    height: 35px;
    background-color: #1C6BFE;
    color: #FFFFFF;
    opacity: 100%;
    font-size: 15px;
    font-weight: 600;
    border-radius: 4px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;



}


.login-submit-button:hover {


    background-color: #0757eb;
}

.forgot {

    font-size: 18px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    color: #707070;
    opacity: 100%;
    padding: 10px;



}

.sig-up-tittle {


    font-size: 15px;
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #707070;
    opacity: 100%;


}



.forgot-password-tittle {
    padding: 15px;
    font-size: 18px;
    font-weight: 500;

    font-family: 'Lato', 'Regular';


    color: #707070;
    opacity: 100%;

}

.loginsymbol {


    position: absolute;

    height: auto;
    margin-left: -6%;
    margin-top: -8%;
    width: 20%;

}


.reseatpasswordsymboll {


    position: absolute;

    height: auto;
    margin-left: -6%;
    margin-top: 0%;
    width: 20%;


}

.erroelogin {

    margin: auto;
    padding: auto;
    text-align: center;
    padding-right: 132px;
    position: relative;

}

.erroeloginpassword {

    margin: auto;
    padding: auto;
    text-align: center;
    padding-right: 196px;
    position: relative;

}


.input-field {
    width: 300px;
    height: 35px;
    background-color: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    /* margin: 8px; */
    border: 1px solid #a2bcf3;
    margin: auto;
    /* box-shadow: #fdd6f1 0px 5px 15px; */



}

.input-field .inputpassword {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: hidden;
    outline: none;
    text-indent: 2px;
    padding: 5px;
    font-size: 12px;

    color: #707070;
    /* opacity: 50%; */
    font-weight: 400;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: auto;

}

.input-field span {
    cursor: pointer;
}







@media screen and (max-width: 425px) {
    .login-bg {
        opacity: 100%;
        background-color: #EFF5FF;
        height: 1300px;
        margin: auto;
        padding: auto;
        text-align: center;





    }
}