.featurelist {

    color: #707070;
    padding: 4px;
}






