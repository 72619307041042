.headingfont {
    color: #606062;
    opacity: 100%;
    font-size: 20px;
    /* font-weight: 500; */
    font-family: 'Raleway', 'SemiBold';

    font-weight: 600;




}

.FooterLinkfont {
    text-decoration: none;
    color: #606062;
    font-size: 10px;
    font-weight: 500;
    font-family: 'Raleway', 'Regular';

}

.logocsss {
    margin-top: 70px;
    text-align: right;
    margin-right: 25%;
    padding-right: 10%;


}

.displyanone {

    margin-top: 20px;
}

.forminline {
    /* display: flex; */
    /* width: 100%; */
    padding: 12px;
    margin: 8px 0;
    width: 150px;
    border: 1px solid #ccc;
    font-size: 13px;
    height: 35px;
    border-radius: 5px;
    /* box-sizing: border-box; */


}

.displyflex {
    display: flex;


}

.subscription {
    font-size: 15px;
    margin-top: 8px;
    width: 100px;
    height: 35px;
    /* margin-left: 10px; */
    text-align: center;
    border-radius: 5px;
    /* padding-left: 10px; */
    background-color: #1C6BFE;
    color: white;
    border: none;


}

/* #################### inline form  */

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    width: 300px;
}



.form-inline .inputform {
    vertical-align: middle;
    /* margin: 5px 10px 5px 0; */
    /* padding: 10px; */
    background-color: #fff;
    border: 1px solid #ddd;
    font-size: 12px;
    width: 50%;
    border-radius: 5px;
    height: 35px;
    padding-left: 4%;
    color: #606062;
    opacity: 100%;

}

.form-inline .buttonform {
    /* padding: 10px 20px; */
    background-color: #1C6BFE;
    border: 1px solid #ddd;
    color: white;
    cursor: pointer;
    width: 100px;

    border-radius: 5px;
    height: 35px;



}

/* 
 */